import {
  CustomCreatePatientDto,
  DigitalIDApi,
  DigitalIdPatientProviderAccess,
  GetPatientProviderAccessResponseDto,
  GetProviderResponseDto,
  ScanResponseDto,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from '../api-client/api-client';

const digitalIdApi = HealthHubApiClient.use(DigitalIDApi);

export type GetPatientProviderAccessRequestDto = {
  patientProviderAccessId: string;
};

export type GetListAllowedPatientsQueryDto = {
  search?: string;
};

export async function upsertProviderDigitalId(
  config?: AxiosRequestConfig,
): Promise<GetProviderResponseDto> {
  try {
    const response = await digitalIdApi.digitalIdControllerUpsert(config);

    return response.data as GetProviderResponseDto;
  } catch (error) {
    throw new Error('Failed to fetch current user provider');
  }
}

export async function scanDigitalId(
  { id }: { id: string },
  config?: AxiosRequestConfig,
): Promise<ScanResponseDto> {
  try {
    const response = await digitalIdApi.digitalIdControllerScan(id, config);

    return response.data as ScanResponseDto;
  } catch (error) {
    throw new Error('Failed to scan Digital ID');
  }
}

export async function exportProfileClients(
  customCreatePatientDto: CustomCreatePatientDto,
  config?: AxiosRequestConfig,
): Promise<void> {
  try {
    const response = await digitalIdApi.digitalIdControllerExportProviderClients(
      customCreatePatientDto,
      {
        responseType: 'blob', // Important to get the response as a blob
        headers: {
          'Content-Type': 'application/json',
          // Add any necessary headers here
        },
      },
    );

    const url = window.URL.createObjectURL(
      new Blob([response.data as unknown as BlobPart], { type: 'application/pdf' }),
    );
    const a = document.createElement('a');
    a.href = url;
    a.download = 'provider_clients_export.pdf';
    document.body.appendChild(a);
    a.click();
    a.remove();

    return;
  } catch (error) {
    throw new Error('Failed to scan Digital ID');
  }
}

export async function getListAllowedPatientsDigitalId(
  params: GetListAllowedPatientsQueryDto,
  config?: AxiosRequestConfig,
): Promise<DigitalIdPatientProviderAccess[]> {
  try {
    const response = await digitalIdApi.digitalIdControllerListAllowedPatients(
      params.search,
      config,
    );

    return response.data as DigitalIdPatientProviderAccess[];
  } catch (error) {
    throw new Error('Failed to fetch list of allowed patients');
  }
}

export async function findOneByIdDigitalId(
  id: string,
  config?: AxiosRequestConfig,
): Promise<GetPatientProviderAccessResponseDto> {
  try {
    const response = await digitalIdApi.digitalIdControllerGetFindOneById(id, config);

    return response.data as GetPatientProviderAccessResponseDto;
  } catch (error: any) {
    throw new Error(error.response.data.message ?? 'Failed to fetch patient provider access');
  }
}
